import type { FC } from 'react'

import { useGetBannerBlockInfoQuery, useGetHomePageEntityQuery } from '@redux/features/homePage/homePage.api'
import { skipToken } from '@reduxjs/toolkit/query/react'

import { TitleStyled } from '@styles/mainPage/title'

const Title: FC = () => {
  const { data } = useGetHomePageEntityQuery()
  const { data: bannerBlockInfo } = useGetBannerBlockInfoQuery(data?.nid || skipToken)

  return (
    <TitleStyled lang="en" level={1} setInnerHtml>
      {bannerBlockInfo?.banner_text || bannerBlockInfo?.title}
      {/* {title} */}
    </TitleStyled>
  )
}

export default Title
