import { memo } from 'react'

import { useGetHomePageEntityQuery } from '@redux/features/homePage/homePage.api'

import ContentBlock from '@components/mainPage/mainBlock/contentBlocks/contentBlock/ContentBlock'

const ContentBlocks = () => {
  const { data } = useGetHomePageEntityQuery()

  return data?.blocks.map(blockId => <ContentBlock blockId={blockId} key={blockId} />)
}

export default memo(ContentBlocks)
